/** generated by icon/build.js - DO NOT EDIT DIRECTLY */
import PropTypes from "prop-types";
import React from "react";
import { IconBase } from "../base";

export const Horoscopes = React.forwardRef(function Horoscopes16(props, ref) {
  const {
    className,
    fill,
    size = "16",
    title = "Horoscopes",
    ...other
  } = props;

  return (
    <IconBase
      ref={ref}
      className={className}
      fill={fill}
      title={title}
      size={size}
      path="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm3.51 7.788a.6.6 0 0 1-.867-.83c.346-.361.533-.659.533-1.195 0-.354-.314-1.085-1.289-1.085-.829 0-1.287 1.256-1.287 2.123v3.585a.6.6 0 0 1-1.2 0V7.8c0-.867-.459-2.123-1.288-2.123-.974 0-1.29.73-1.29 1.085 0 .536.188.833.534 1.195a.6.6 0 0 1-.867.83c-.429-.449-.867-1.04-.867-2.025 0-1.099.952-2.285 2.49-2.285.82 0 1.46.474 1.888 1.156.428-.682 1.068-1.156 1.887-1.156 1.538 0 2.49 1.186 2.49 2.285 0 .986-.439 1.577-.867 2.025z"
      fillRule="nonzero"
      {...other}
    />
  );
});

Horoscopes.displayName = "Horoscopes16";

Horoscopes.propTypes = {
  /** SVG's title, pass false to disable icon titles */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  /** Fill color - You can find those under Colors */
  fill: PropTypes.string,
  /** Our approved icon sizes */
  size: PropTypes.oneOf(["16", "24", "32"])
};
