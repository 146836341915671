/** generated by icon/build.js - DO NOT EDIT DIRECTLY */
import PropTypes from "prop-types";
import React from "react";
import { IconBase } from "../base";

export const Mahjongg = React.forwardRef(function Mahjongg16(props, ref) {
  const { className, fill, size = "16", title = "Mahjongg", ...other } = props;

  return (
    <IconBase
      ref={ref}
      className={className}
      fill={fill}
      title={title}
      size={size}
      path="M2 5.408l6 3.488v6.079l-6-3.599V5.408zm12.8.028v5.94L9 14.854V8.808l5.8-3.372zm-6.491-4.41l6.012 3.657-5.988 3.535L2.32 4.67 8.31 1.025z"
      fillRule="nonzero"
      {...other}
    />
  );
});

Mahjongg.displayName = "Mahjongg";

Mahjongg.propTypes = {
  /** SVG's title, pass false to disable icon titles */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  /** Fill color - You can find those under Colors */
  fill: PropTypes.string,
  /** Our approved icon sizes */
  size: PropTypes.oneOf(["16", "24", "32"])
};
