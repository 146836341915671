/** generated by icon/build.js - DO NOT EDIT DIRECTLY */
import PropTypes from "prop-types";
import React from "react";
import { IconBase } from "../base";

export const Solitaire = React.forwardRef(function Solitaire16(props, ref) {
  const { className, fill, size = "16", title = "Solitaire", ...other } = props;

  return (
    <IconBase
      ref={ref}
      className={className}
      fill={fill}
      title={title}
      size={size}
      path="M12.269 5.466C11.433 4.642 8.672 2.568 7.96 1h-.24C7.032 2.565 4.38 4.62 3.57 5.453c-.81.833-1.492 1.404-1.492 3.007 0 1.957 1.166 3.126 3.115 3.126 1.205 0 1.818-.884 2.182-1.698-.24 2.135-.655 4.688-1.897 4.688V15h4.966v-.356c-1.242 0-1.7-2.621-1.941-4.756.364.815.925 1.698 2.13 1.698 1.95 0 3.118-1.17 3.118-3.126 0-1.603-.646-2.17-1.482-2.994z"
      fillRule="nonzero"
      {...other}
    />
  );
});

Solitaire.displayName = "Solitaire16";

Solitaire.propTypes = {
  /** SVG's title, pass false to disable icon titles */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  /** Fill color - You can find those under Colors */
  fill: PropTypes.string,
  /** Our approved icon sizes */
  size: PropTypes.oneOf(["16", "24", "32"])
};
