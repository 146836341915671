import React from "react";
import PropTypes from "prop-types";
import { theme, Icon } from "@washingtonpost/wpds-ui-kit";

// wpds-assets
import Comment from "@washingtonpost/wpds-assets/asset/comment";
import Crossword from "@washingtonpost/wpds-assets/asset/crossword";
import Keyword from "@washingtonpost/wpds-assets/asset/keyword";
import OnTheRecord from "@washingtonpost/wpds-assets/asset/on-the-record";
import Sudoku from "@washingtonpost/wpds-assets/asset/sudoku";
import { Mahjongg } from "../../../core/icons/mahjongg";
import { Horoscopes } from "../../../core/icons/horoscopes";
import { Solitaire } from "../../../core/icons/solitaire";

const Item = ({ linkWrapperClass, iconName, item, itemUrl }) => {
  const itemStyle = {
    marginRight: theme.space["025"],
    color: theme.colors.gray40
  };
  const icons = {
    comment: Comment,
    crossword: Crossword,
    keyword: Keyword,
    mahjongg: Mahjongg,
    sudoku: Sudoku,
    horoscopes: Horoscopes,
    solitaire: Solitaire,
    ontherecord: OnTheRecord
  };
  const GameIcon = icons[iconName];
  return (
    <a
      className={linkWrapperClass}
      href={itemUrl}
      style={{ whiteSpace: "nowrap" }}
    >
      <Icon label="" style={itemStyle}>
        <GameIcon />
      </Icon>
      <span>{item}</span>
    </a>
  );
};

Item.propTypes = {
  iconName: PropTypes.string,
  iconVariant: PropTypes.string,
  item: PropTypes.string,
  itemUrl: PropTypes.string,
  linkWrapperClass: PropTypes.string
};

export default Item;
