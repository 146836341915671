import PropTypes from "prop-types";
import React from "react";
import { getClasses } from "@washingtonpost/front-end-utils";

/**
 *
 <IconBase title="add" path="M7 7V1h2v6h5.929v2H9v5.929H7V9H1V7h6z" fillRule="nonzero" />
 */

export const IconBase = React.forwardRef(function IconBase(props, ref) {
  const {
    className = "",
    size = "16",
    fillRule = "nonzero",
    fill = "",
    title = "",
    path = "",
    viewBox = "",
    ...other
  } = props;

  const fillClass = fill ? `fill-${fill}` : "";

  return path ? (
    <svg
      ref={ref}
      className={getClasses("content-box", {
        [fillClass]: fillClass,
        [className]: className
      })}
      width={size}
      height={size}
      viewBox={viewBox || `0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      {...other}
      aria-hidden="true"
      focusable="false"
      role="img"
    >
      {title !== "" && <title>{title}</title>}
      <path d={path} fillRule={fillRule} />
    </svg>
  ) : null;
});

IconBase.displayName = "IconBase";

IconBase.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  fill: PropTypes.string,
  fillRule: PropTypes.string,
  size: PropTypes.string,
  viewBox: PropTypes.string
};
